<template>
  <v-col cols="12">
    <UpdateList
      :customers="customers"
      :tasks="tasks"
      :customer="customer"
      :task-loading="taskLoading"
      :customer-loading="customerLoading"
      @load:update-list="lazyLoadUpdateList"
    />
  </v-col>
</template>

<script>
import UpdateList from '@/components/admin/partials/Customer/View/Basic/UpdateHistory/UpdateList'
import { mapGetters } from 'vuex'
export default {
  name: 'Index.vue',
  components: { UpdateList },

  computed: {
    ...mapGetters([
      'activities',
      'allTasksData',
      'allPrefectures',
      'servicesBySite',
      'customer',
      'activityPagination',
      'taskPagination',
      'customerTasksCount',
      'customerClaimsCount',
      'customerCoatingAfterCount',
      'customerVisitMemosCount'
    ])
  },

  data() {
    return {
      page: {
        customer: 0,
        task: 0
      },
      customers: [],
      tasks: [],
      customerLoading: false,
      taskLoading: false,
      smallLoading: false
    }
  },

  mounted() {
    this.getDataFromApi()
  },

  methods: {
    getDataFromApi() {
      this.fetchCustomerHistory()
      this.fetchTaskHistory()
    },
    async fetchCustomerHistory(options) {
      this.customerLoading = true
      let param = {
        orderBy: 'updated_at',
        orderDesc: 'desc',
        paginate: 5,
        history: true,
        ...options
      }
      await this.$store.dispatch('CUSTOMER_GET_ALL', param)
      this.page.customer = this.activityPagination.current_page
      this.customers.push(...this.activities)
      this.customerLoading = false
    },
    async fetchTaskHistory(options) {
      this.taskLoading = true
      let param = {
        orderBy: 'updated_at',
        orderDesc: 'desc',
        paginate: 10,
        ...options
      }
      await this.$store.dispatch('GET_ALL_TASK', param)
      this.page.task = this.taskPagination.current_page
      this.tasks.push(...this.allTasksData)
      this.taskLoading = false
    },
    lazyLoadUpdateList(type) {
      if (type === 'customer') {
        if (this.page.customer !== this.activityPagination.total_pages) {
          this.page.customer++
          this.smallLoading = true
          this.fetchCustomerHistory({
            page: this.page.customer
          })
          this.smallLoading = false
        }
      } else {
        if (this.page.task !== this.taskPagination.total_pages) {
          this.page.task++
          this.smallLoading = true
          this.fetchTaskHistory({
            page: this.page.task
          })
          this.smallLoading = false
        }
      }
    },
  }
}
</script>

<style scoped></style>
