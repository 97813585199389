var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('UpdateList', {
    attrs: {
      "customers": _vm.customers,
      "tasks": _vm.tasks,
      "customer": _vm.customer,
      "task-loading": _vm.taskLoading,
      "customer-loading": _vm.customerLoading
    },
    on: {
      "load:update-list": _vm.lazyLoadUpdateList
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }